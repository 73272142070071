import React from 'react';

import Layout from "../../components/layout"

const Team = ({ location }) => (
  <Layout location={location}>
    <div>
      <h1>Team</h1>
      <p>This is the team page!</p>
    </div>
  </Layout>
)

export default Team